import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PaymentService} from '@caroo/payment/payment.service';
import {first} from 'rxjs/operators';
import {Plan, SubscriptionState} from '../../../generated/graphql';

@Component({
	selector: 'caroo-offline-payments-dialog',
	templateUrl: './offline-payments-dialog.component.html',
	styleUrls: ['./offline-payments-dialog.component.scss']
})
export class OfflinePaymentsDialogComponent {
	private readonly subscriptionState: SubscriptionState;
	isEmailSent = false;

	selectedPlan?: Plan;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: { subscriptionState: SubscriptionState, plan?: Plan },
		private readonly paymentService: PaymentService
	) {
		this.subscriptionState = data.subscriptionState;
		this.selectedPlan = data.plan;
	}

	setupOfflineSubscription(plan: Plan) {
		switch (this.subscriptionState) {
			case SubscriptionState.NONE:
			case SubscriptionState.BYPASS:
				this.paymentService.createOfflineSubscription(plan).pipe(first()).subscribe(console.log, console.error);
				this.isEmailSent = true;
				break;
			case SubscriptionState.ACTIVE:
			case SubscriptionState.FREE_TRIAL:
				break;
			case SubscriptionState.CANCELLED:
			case SubscriptionState.CANCELLED_PAYMENT_FAILED:
				this.paymentService.reactivateOfflineSubscription().pipe(first()).subscribe(console.log, console.error);
				this.isEmailSent = true;
				break;
		}
	}
}
