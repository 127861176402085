import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Plan} from '../../../generated/graphql';

@Component({
	selector: 'caroo-subscription-plan-options',
	templateUrl: './subscription-plan-options.component.html',
	styleUrls: ['./subscription-plan-options.component.scss']
})
export class SubscriptionPlanOptionsComponent {
	@Input()
	selectedPlan?: Plan;

	@Output()
	readonly selectedPlanChange = new EventEmitter<Plan>();

	Plan = Plan;

	selectPlan(plan: Plan) {
		this.selectedPlan = plan;
		this.selectedPlanChange.emit(plan);
	}
}
