import { environment } from '../../environments/environment';
import { DemoFirebaseConfig, DevFirebaseConfig, ProductionFirebaseConfig, StagingFirebaseConfig } from '../../environments/firebase-config';
import * as i0 from "@angular/core";
export class ConfigService {
    constructor() {
        this.environments = {
            'localhost:4200': 'development',
            '192.168.1.2:4200': 'development',
            '': 'staging',
            'portal.qa.caroo.co.uk': 'staging',
            'portal-qa.caroo.web.app': 'staging',
            'portal-qa-caroo.firebaseapp.com': 'staging',
            'portal.staging.caroo.co.uk': 'staging',
            'portal-staging-caroo.web.app': 'staging',
            'portal-staging-caroo.firebaseapp.com': 'staging',
            'portal.demo.caroo.co.uk': 'demo',
            'portal-demo-caroo.web.app': 'demo',
            'portal-demo-caroo.firebaseapp.com': 'demo',
            'portal.caroo.co.uk': 'production',
            'portal-caroo.web.app': 'production',
            'portal-caroo.firebaseapp.com': 'production'
        };
        this.configs = {
            development: {
                apiUrl: 'https://caroo-legacy.eu.ngrok.io/v1',
                graphqlApiUrl: 'https://caroo.eu.ngrok.io/graphql',
                brandApiUrl: 'https://localhost:3333/graphql',
                socketUrl: 'http://localhost:3000',
                googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
                chargebeeSite: 'caroo-test',
                firebase: new DevFirebaseConfig()
            },
            staging: {
                apiUrl: 'https://api.staging.caroo.co.uk/v1',
                graphqlApiUrl: 'https://graphql-api.staging.caroo.co.uk/graphql',
                brandApiUrl: 'https://brand-api.staging.caroo.co.uk/graphql',
                socketUrl: 'https://api.staging.caroo.co.uk',
                googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
                chargebeeSite: 'caroo-test',
                firebase: new StagingFirebaseConfig()
            },
            demo: {
                apiUrl: 'https://api.demo.caroo.co.uk/v1',
                graphqlApiUrl: 'https://graphql-api.demo.caroo.co.uk/graphql',
                brandApiUrl: 'https://brand-api.demo.caroo.co.uk/graphql',
                socketUrl: 'https://api.demo.caroo.co.uk',
                googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
                chargebeeSite: 'caroo-test',
                firebase: new DemoFirebaseConfig()
            },
            production: {
                apiUrl: 'https://api.caroo.co.uk/v1',
                graphqlApiUrl: 'https://graphql-api.caroo.co.uk/graphql',
                brandApiUrl: 'https://brand-api.caroo.co.uk/graphql',
                socketUrl: 'https://api.caroo.co.uk',
                googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
                chargebeeSite: 'caroo',
                firebase: new ProductionFirebaseConfig()
            }
        };
    }
    get environment() {
        return this.environments[window.location.host];
    }
    get config() {
        return this.configs[this.environment];
    }
    get apiUrl() {
        return this.config.apiUrl;
    }
    get graphqlApiUrl() {
        return this.config.graphqlApiUrl;
    }
    get brandApiUrl() {
        return this.config.brandApiUrl;
    }
    get socketUrl() {
        return this.config.socketUrl;
    }
    get googleApisKey() {
        return this.config.googleApisKey;
    }
    get chargebeeSite() {
        return this.config.chargebeeSite;
    }
    get firebase() {
        return this.config.firebase;
    }
    initEnvironment() {
        environment.baseUrl = this.apiUrl;
        environment.graphqlApiUrl = this.graphqlApiUrl;
        environment.socketUrl = this.socketUrl;
        environment.googleApisKey = this.googleApisKey;
        environment.firebase = this.firebase;
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
