import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {OfflinePaymentsDialogComponent} from '@caroo/payment/offline-payments-dialog/offline-payments-dialog.component';
import {SubscriptionPlanOptionsModule} from '@caroo/payment/subscription-plan-options/subscription-plan-options.module';
import {ButtonModule} from '@caroo/shared/button/button.module';

@NgModule({
	imports: [
		ButtonModule,
		CommonModule,
		MatIconModule,
		SubscriptionPlanOptionsModule
	],
	declarations: [OfflinePaymentsDialogComponent],
	entryComponents: [OfflinePaymentsDialogComponent],
	exports: [OfflinePaymentsDialogComponent]
})
export class OfflinePaymentsDialogModule {
}
