import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any,
};



export enum AccountType {
  ADMIN = 'ADMIN',
  BRAND = 'BRAND',
  CANDIDATE = 'CANDIDATE',
  SUPERADMIN = 'SUPERADMIN'
}

export type AnonymousCommenter = Commenter & {
   __typename?: 'AnonymousCommenter',
  id: Scalars['Int'],
};

export type AnonymousLiker = Liker & {
   __typename?: 'AnonymousLiker',
  id: Scalars['Int'],
};

export type Article = Content & {
   __typename?: 'Article',
  author: Employee,
  comments: Array<Comment>,
  createdAt: Scalars['DateTime'],
  header?: Maybe<Resource>,
  id: Scalars['Int'],
  likes: Array<Like>,
  numComments: Scalars['Int'],
  numLikes: Scalars['Int'],
  numViews: Scalars['Int'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  sections: Array<ArticleSection>,
  targeting: ContentTargeting,
  title: Scalars['String'],
  updatedAt: Scalars['DateTime'],
};


export type ArticleLikesArgs = {
  paging: OffsetPagingInput
};

export type ArticleSection = {
  id: Scalars['Int'],
};

export type Brand = {
   __typename?: 'Brand',
  allContent: Array<Content>,
  brandStory?: Maybe<Scalars['String']>,
  companiesHouseId: Scalars['String'],
  content?: Maybe<Content>,
  contentActivity: Array<ContentActivity>,
  createdAt: Scalars['DateTime'],
  culture?: Maybe<Scalars['String']>,
  draftContent: Array<Content>,
  header?: Maybe<Resource>,
  headerVideo?: Maybe<Resource>,
  id: Scalars['Int'],
  invites: Array<BrandInvite>,
  isFollowed: Scalars['Boolean'],
  keywords: Array<Interest>,
  locations: Array<Location>,
  logo?: Maybe<Resource>,
  media: Array<Resource>,
  name?: Maybe<Scalars['String']>,
  numFollowers: Scalars['Int'],
  numRoles: Scalars['Int'],
  numViews: Scalars['Int'],
  perks: Array<Perk>,
  publishedContent: Array<Content>,
  role?: Maybe<Role>,
  roles: Array<Role>,
  subscriptionState: SubscriptionState,
  tagLine?: Maybe<Scalars['String']>,
  totalComments: Scalars['Int'],
  totalLikes: Scalars['Int'],
  totalViews: Scalars['Int'],
  transportAndAmenities?: Maybe<Scalars['String']>,
  underReview: Scalars['Boolean'],
  updatedAt: Scalars['DateTime'],
};


export type BrandAllContentArgs = {
  paging: OffsetPagingInput
};


export type BrandContentArgs = {
  id: Scalars['Int']
};


export type BrandContentActivityArgs = {
  paging: OffsetPagingInput
};


export type BrandDraftContentArgs = {
  paging: OffsetPagingInput
};


export type BrandPublishedContentArgs = {
  paging: OffsetPagingInput
};


export type BrandRoleArgs = {
  id: Scalars['Int']
};


export type BrandRolesArgs = {
  state?: Maybe<ScheduleState>
};

export type BrandInvite = {
   __typename?: 'BrandInvite',
  email: Scalars['String'],
};

export type Candidate = ConversationParticipant & {
   __typename?: 'Candidate',
  avatar?: Maybe<Resource>,
  bio?: Maybe<Scalars['String']>,
  highlights: Highlights,
  id: Scalars['Int'],
  isSelf: Scalars['Boolean'],
  location?: Maybe<Location>,
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type CareerHighlight = {
   __typename?: 'CareerHighlight',
  company: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  from: Scalars['DateTime'],
  id: Scalars['Int'],
  title: Scalars['String'],
  to?: Maybe<Scalars['DateTime']>,
};

export type ChargebeeHostedPage = {
   __typename?: 'ChargebeeHostedPage',
  created_at: Scalars['Float'],
  embed: Scalars['Boolean'],
  expires_at: Scalars['Float'],
  id: Scalars['String'],
  object: Scalars['String'],
  resource_version: Scalars['Float'],
  state: Scalars['String'],
  type: Scalars['String'],
  updated_at: Scalars['Float'],
  url: Scalars['String'],
};

export type ChargebeeLinkedCustomer = {
   __typename?: 'ChargebeeLinkedCustomer',
  customer_id: Scalars['String'],
  email: Scalars['String'],
  has_active_subscription: Scalars['Boolean'],
  has_billing_address: Scalars['Boolean'],
  has_payment_method: Scalars['Boolean'],
  object: Scalars['String'],
};

export type ChargebeePortalSession = {
   __typename?: 'ChargebeePortalSession',
  access_url: Scalars['String'],
  created_at: Scalars['Float'],
  customer_id: Scalars['String'],
  expires_at: Scalars['Float'],
  id: Scalars['String'],
  linked_customers: Array<ChargebeeLinkedCustomer>,
  object: Scalars['String'],
  status: Scalars['String'],
  token: Scalars['String'],
};

export type Colleague = {
   __typename?: 'Colleague',
  email: Scalars['String'],
  name?: Maybe<Scalars['String']>,
};

export type Comment = {
   __typename?: 'Comment',
  commenter: Commenter,
  comments: Array<Comment>,
  content: Content,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  text: Scalars['String'],
};

export type Commenter = {
  id: Scalars['Int'],
};

export type CommentOnCommentInput = {
  commentId: Scalars['Int'],
  contentId: Scalars['Int'],
  text: Scalars['String'],
};

export type CommentOnContentInput = {
  contentId: Scalars['Int'],
  text: Scalars['String'],
};

export type CommentOnContentPayload = {
   __typename?: 'CommentOnContentPayload',
  comment: Comment,
  content: Content,
};

export type CompaniesHouseCompany = {
   __typename?: 'CompaniesHouseCompany',
  id: Scalars['String'],
  name: Scalars['String'],
};

export type Content = {
  author: Employee,
  comments: Array<Comment>,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  likes: Array<Like>,
  numComments: Scalars['Int'],
  numLikes: Scalars['Int'],
  numViews: Scalars['Int'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  targeting: ContentTargeting,
  updatedAt: Scalars['DateTime'],
};


export type ContentLikesArgs = {
  paging: OffsetPagingInput
};

export type ContentActivity = Comment | Like;

export type ContentTargeting = {
   __typename?: 'ContentTargeting',
  industryExperiences: Array<IndustryExperience>,
  location?: Maybe<ContentTargetingLocation>,
  skills: Array<Skill>,
};

export type ContentTargetingLocation = {
   __typename?: 'ContentTargetingLocation',
  includeRemoteWorkers: Scalars['Boolean'],
  latitude: Scalars['Float'],
  location: Scalars['String'],
  longitude: Scalars['Float'],
  maxDistance: Scalars['Int'],
};

export type ContentTargetingLocationInput = {
  includeRemoteWorkers: Scalars['Boolean'],
  latitude: Scalars['Float'],
  location: Scalars['String'],
  longitude: Scalars['Float'],
  maxDistance: Scalars['Float'],
};

export type Conversation = {
   __typename?: 'Conversation',
  candidate: Candidate,
  candidateLastReadAt?: Maybe<Scalars['DateTime']>,
  employee: Employee,
  employeeLastReadAt?: Maybe<Scalars['DateTime']>,
  id: Scalars['Int'],
  messages: Array<Message>,
  numUnreadMessages: Scalars['Int'],
  role: Role,
};

export type ConversationParticipant = {
  avatar?: Maybe<Resource>,
  isSelf: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
};

export type CreateArticleInput = {
  title: Scalars['String'],
};

export type CreateArticlePayload = {
   __typename?: 'CreateArticlePayload',
  article: Article,
};

export type CreateChargebeeHostedPageForExternalFinancierInput = {
  plan?: Maybe<Plan>,
  token: Scalars['String'],
  type: HostedPageType,
};

export type CreateChargebeeHostedPageInput = {
  plan?: Maybe<Plan>,
  type: HostedPageType,
};

export type CreateChargebeePortalSessionForExternalFinancierInput = {
  token: Scalars['String'],
};

export type CreateOfflineSubscriptionInput = {
  plan: Plan,
};

export type CreateOfflineSubscriptionPayload = {
   __typename?: 'CreateOfflineSubscriptionPayload',
  success: Scalars['Boolean'],
};

export type CreatePostInput = {
  text: Scalars['String'],
};

export type CreatePostPayload = {
   __typename?: 'CreatePostPayload',
  post: Post,
};

export type CreateResourceArticleSectionInput = {
  articleId: Scalars['Int'],
  contentType?: Maybe<Scalars['String']>,
  youTubeUrl?: Maybe<Scalars['String']>,
};

export type CreateResourceArticleSectionPayload = {
   __typename?: 'CreateResourceArticleSectionPayload',
  uploadUrl?: Maybe<Scalars['String']>,
};

export type CreateTextArticleSectionInput = {
  articleId: Scalars['Int'],
  text: Scalars['String'],
};

export type CreateTextArticleSectionPayload = {
   __typename?: 'CreateTextArticleSectionPayload',
  article: Article,
};


export type EducationHighlight = {
   __typename?: 'EducationHighlight',
  description?: Maybe<Scalars['String']>,
  from: Scalars['DateTime'],
  grade?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  institution: Scalars['String'],
  qualification: Scalars['String'],
  to?: Maybe<Scalars['DateTime']>,
};

export type Employee = ConversationParticipant & {
   __typename?: 'Employee',
  avatar?: Maybe<Resource>,
  brand: Brand,
  colleagues: Array<Colleague>,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  isSelf: Scalars['Boolean'],
  jobTitle?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notifications: Array<EmployeeNotification>,
  role?: Maybe<Role>,
  roles: Array<Role>,
  updatedAt: Scalars['DateTime'],
};


export type EmployeeRoleArgs = {
  id: Scalars['Int']
};


export type EmployeeRolesArgs = {
  state?: Maybe<ScheduleState>
};

export type EmployeeNotification = {
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  read: Scalars['Boolean'],
};

export type EstimateContentTargetingReachInput = {
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<EstimateContentTargetingReachLocationInput>,
  skillIds: Array<Scalars['Int']>,
};

export type EstimateContentTargetingReachLocationInput = {
  includeRemoteWorkers: Scalars['Boolean'],
  latitude: Scalars['Float'],
  location: Scalars['String'],
  longitude: Scalars['Float'],
  maxDistance: Scalars['Float'],
};

export type EstimatedContentTargetingReach = {
   __typename?: 'EstimatedContentTargetingReach',
  estimatedIndustryExperienceCandidateReaches: Array<EstimatedIndustryExperienceCandidateReach>,
  estimatedLocationCandidateReach?: Maybe<EstimatedLocationCandidateReach>,
  estimatedSkillCandidateReaches: Array<EstimatedSkillCandidateReach>,
  potentialReach: Scalars['Int'],
  totalReach: Scalars['Int'],
};

export type EstimatedIndustryExperienceCandidateReach = {
   __typename?: 'EstimatedIndustryExperienceCandidateReach',
  industryExperience: IndustryExperience,
  reach: Scalars['Int'],
};

export type EstimatedLocationCandidateReach = {
   __typename?: 'EstimatedLocationCandidateReach',
  location: Scalars['String'],
  maxDistance: Scalars['Int'],
  reach: Scalars['Int'],
};

export type EstimatedSkillCandidateReach = {
   __typename?: 'EstimatedSkillCandidateReach',
  reach: Scalars['Int'],
  skill: Skill,
};

export type ExternalFinancierSubscriptionStateInput = {
  token: Scalars['String'],
};

export type FollowBrandInput = {
  id: Scalars['Int'],
};

export type FollowBrandPayload = {
   __typename?: 'FollowBrandPayload',
  brand: Brand,
};

export type Highlights = {
   __typename?: 'Highlights',
  career: Array<CareerHighlight>,
  education: Array<EducationHighlight>,
  personal: Array<PersonalHighlight>,
};

export enum HostedPageType {
  CHECKOUT_EXISTING = 'CHECKOUT_EXISTING',
  CHECKOUT_NEW = 'CHECKOUT_NEW',
  MANAGE_PAYMENT_SOURCES = 'MANAGE_PAYMENT_SOURCES'
}

export type IndustryExperience = {
   __typename?: 'IndustryExperience',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Interest = {
   __typename?: 'Interest',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Like = {
   __typename?: 'Like',
  content: Content,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  liker: Liker,
};

export type Liker = {
  id: Scalars['Int'],
};

export type Location = {
   __typename?: 'Location',
  id: Scalars['Int'],
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  name: Scalars['String'],
};

export type LocationInput = {
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  name: Scalars['String'],
};

export type Message = {
   __typename?: 'Message',
  conversation: Conversation,
  id: Scalars['Int'],
  message: Scalars['String'],
  participant: ConversationParticipant,
  sent: Scalars['DateTime'],
};

export type Mutation = {
   __typename?: 'Mutation',
  commentOnComment: CommentOnContentPayload,
  commentOnContent: CommentOnContentPayload,
  createArticle: CreateArticlePayload,
  createChargebeeHostedPage: ChargebeeHostedPage,
  createChargebeeHostedPageForExternalFinancier: ChargebeeHostedPage,
  createChargebeePortalSession: ChargebeePortalSession,
  createChargebeePortalSessionForExternalFinancier: ChargebeePortalSession,
  createOfflineSubscription: CreateOfflineSubscriptionPayload,
  createPost: CreatePostPayload,
  createResourceArticleSection: CreateResourceArticleSectionPayload,
  createTextArticleSection: CreateTextArticleSectionPayload,
  followBrand: FollowBrandPayload,
  notifyEmployeeOfExternalPayment: NotifyEmployeeOfExternalPaymentPayload,
  publishContent: PublishContentPayload,
  reactivateOfflineSubscription: ReactivateOfflineSubscriptionPayload,
  removeArticleHeader: RemoveArticleHeaderPayload,
  removeArticleSection: RemoveArticleSectionPayload,
  removeContent: RemoveContentPayload,
  removePostMedia: RemovePostMediaPayload,
  requestExternalPayment: RequestExternalPaymentPayload,
  sendMessage: SendMessagePayload,
  swapArticleSectionIndices: SwapArticleSectionIndicesPayload,
  unfollowBrand: UnfollowBrandPayload,
  updateArticle: UpdateArticlePayload,
  updateArticleHeader: UpdateArticleHeaderPayload,
  updateBrand: UpdateBrandPayload,
  updateContentTargeting: UpdateContentTargetingPayload,
  updateEmployee: UpdateEmployeePayload,
  updateEmployeeAvatar: UpdateEmployeeAvatarPayload,
  updatePost: UpdatePostPayload,
  updatePostMedia: UpdatePostMediaPayload,
  updateTextArticleSection: UpdateTextArticleSectionPayload,
};


export type MutationCommentOnCommentArgs = {
  input: CommentOnCommentInput
};


export type MutationCommentOnContentArgs = {
  input: CommentOnContentInput
};


export type MutationCreateArticleArgs = {
  input: CreateArticleInput
};


export type MutationCreateChargebeeHostedPageArgs = {
  input: CreateChargebeeHostedPageInput
};


export type MutationCreateChargebeeHostedPageForExternalFinancierArgs = {
  input: CreateChargebeeHostedPageForExternalFinancierInput
};


export type MutationCreateChargebeePortalSessionForExternalFinancierArgs = {
  input: CreateChargebeePortalSessionForExternalFinancierInput
};


export type MutationCreateOfflineSubscriptionArgs = {
  input: CreateOfflineSubscriptionInput
};


export type MutationCreatePostArgs = {
  input: CreatePostInput
};


export type MutationCreateResourceArticleSectionArgs = {
  input: CreateResourceArticleSectionInput
};


export type MutationCreateTextArticleSectionArgs = {
  input: CreateTextArticleSectionInput
};


export type MutationFollowBrandArgs = {
  input: FollowBrandInput
};


export type MutationNotifyEmployeeOfExternalPaymentArgs = {
  input: NotifyEmployeeOfExternalPaymentInput
};


export type MutationPublishContentArgs = {
  input: PublishContentInput
};


export type MutationRemoveArticleHeaderArgs = {
  input: RemoveArticleHeaderInput
};


export type MutationRemoveArticleSectionArgs = {
  input: RemoveArticleSectionInput
};


export type MutationRemoveContentArgs = {
  input: RemoveContentInput
};


export type MutationRemovePostMediaArgs = {
  input: RemovePostMediaInput
};


export type MutationRequestExternalPaymentArgs = {
  input: RequestExternalPaymentInput
};


export type MutationSendMessageArgs = {
  input: SendMessageInput
};


export type MutationSwapArticleSectionIndicesArgs = {
  input: SwapArticleSectionIndicesInput
};


export type MutationUnfollowBrandArgs = {
  input: UnfollowBrandInput
};


export type MutationUpdateArticleArgs = {
  input: UpdateArticleInput
};


export type MutationUpdateArticleHeaderArgs = {
  input: UpdateArticleHeaderInput
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput
};


export type MutationUpdateContentTargetingArgs = {
  input: UpdateContentTargetingInput
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput
};


export type MutationUpdateEmployeeAvatarArgs = {
  input: UpdateEmployeeAvatarInput
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput
};


export type MutationUpdatePostMediaArgs = {
  input: UpdatePostMediaInput
};


export type MutationUpdateTextArticleSectionArgs = {
  input: UpdateTextArticleSectionInput
};

export type NamedCommenter = Commenter & {
   __typename?: 'NamedCommenter',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type NamedLiker = Liker & {
   __typename?: 'NamedLiker',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type NewContentCommentEmployeeNotification = EmployeeNotification & {
   __typename?: 'NewContentCommentEmployeeNotification',
  comment: Comment,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  read: Scalars['Boolean'],
};

export type NewInterestedCandidateEmployeeNotification = EmployeeNotification & {
   __typename?: 'NewInterestedCandidateEmployeeNotification',
  candidate: Candidate,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  read: Scalars['Boolean'],
  role: Role,
  score: Scalars['Float'],
};

export type NewMatchedCandidateEmployeeNotification = EmployeeNotification & {
   __typename?: 'NewMatchedCandidateEmployeeNotification',
  candidate: Candidate,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  read: Scalars['Boolean'],
  role: Role,
  score: Scalars['Float'],
};

export type NewMessageEmployeeNotification = EmployeeNotification & {
   __typename?: 'NewMessageEmployeeNotification',
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  message: Message,
  read: Scalars['Boolean'],
};

export type NotifyEmployeeOfExternalPaymentInput = {
  token: Scalars['String'],
};

export type NotifyEmployeeOfExternalPaymentPayload = {
   __typename?: 'NotifyEmployeeOfExternalPaymentPayload',
  success: Scalars['Boolean'],
};

export type OffsetPagingInput = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
};

export type Perk = {
   __typename?: 'Perk',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type PersonalHighlight = {
   __typename?: 'PersonalHighlight',
  date: Scalars['DateTime'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  organisation: Scalars['String'],
  title: Scalars['String'],
};

export enum Plan {
  FULL_SERVICE = 'FULL_SERVICE',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export type Post = Content & {
   __typename?: 'Post',
  author: Employee,
  comments: Array<Comment>,
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  likes: Array<Like>,
  media?: Maybe<Resource>,
  numComments: Scalars['Int'],
  numLikes: Scalars['Int'],
  numViews: Scalars['Int'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  targeting: ContentTargeting,
  text: Scalars['String'],
  updatedAt: Scalars['DateTime'],
};


export type PostLikesArgs = {
  paging: OffsetPagingInput
};

export type PublishContentInput = {
  id: Scalars['Int'],
};

export type PublishContentPayload = {
   __typename?: 'PublishContentPayload',
  content: Content,
};

export type Query = {
   __typename?: 'Query',
  brand: Brand,
  estimateContentTargetingReach: EstimatedContentTargetingReach,
  externalFinancierSubscriptionState: SubscriptionState,
  industryExperiences: Array<IndustryExperience>,
  interests: Array<Interest>,
  me: User,
  searchCompaniesHouse: Array<CompaniesHouseCompany>,
};


export type QueryBrandArgs = {
  id: Scalars['Int']
};


export type QueryEstimateContentTargetingReachArgs = {
  input: EstimateContentTargetingReachInput
};


export type QueryExternalFinancierSubscriptionStateArgs = {
  input: ExternalFinancierSubscriptionStateInput
};


export type QuerySearchCompaniesHouseArgs = {
  query: Scalars['String']
};

export type ReactivateOfflineSubscriptionPayload = {
   __typename?: 'ReactivateOfflineSubscriptionPayload',
  success: Scalars['Boolean'],
};

export type RemoveArticleHeaderInput = {
  articleId: Scalars['Int'],
};

export type RemoveArticleHeaderPayload = {
   __typename?: 'RemoveArticleHeaderPayload',
  article: Article,
};

export type RemoveArticleSectionInput = {
  articleId: Scalars['Int'],
  sectionId: Scalars['Int'],
};

export type RemoveArticleSectionPayload = {
   __typename?: 'RemoveArticleSectionPayload',
  article: Article,
};

export type RemoveContentInput = {
  contentId: Scalars['Int'],
};

export type RemoveContentPayload = {
   __typename?: 'RemoveContentPayload',
  success: Scalars['Boolean'],
};

export type RemovePostMediaInput = {
  postId: Scalars['Int'],
};

export type RemovePostMediaPayload = {
   __typename?: 'RemovePostMediaPayload',
  post: Post,
};

export type RequestExternalPaymentInput = {
  employeeName: Scalars['String'],
  externalFinancierEmail: Scalars['String'],
  externalFinancierName: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  plan: Plan,
};

export type RequestExternalPaymentPayload = {
   __typename?: 'RequestExternalPaymentPayload',
  success: Scalars['Boolean'],
};

export type Resource = {
   __typename?: 'Resource',
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  resource: Scalars['String'],
  type: ResourceType,
  updatedAt: Scalars['DateTime'],
};

export type ResourceArticleSection = ArticleSection & {
   __typename?: 'ResourceArticleSection',
  id: Scalars['Int'],
  resource: Resource,
};

export enum ResourceType {
  COLOUR = 'COLOUR',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  YOUTUBE = 'YOUTUBE'
}

export type Role = {
   __typename?: 'Role',
  description?: Maybe<Scalars['String']>,
  description1?: Maybe<Scalars['String']>,
  description2?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  title: Scalars['String'],
};

export enum ScheduleState {
  LIVE = 'LIVE',
  SAVED = 'SAVED',
  SCHEDULED = 'SCHEDULED'
}

export type SendMessageInput = {
  conversationId: Scalars['Int'],
  message: Scalars['String'],
};

export type SendMessagePayload = {
   __typename?: 'SendMessagePayload',
  conversation: Conversation,
  message: Message,
};

export type Skill = {
   __typename?: 'Skill',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Subscription = {
   __typename?: 'Subscription',
  messageSent: Message,
  notificationReceived: EmployeeNotification,
  subscriptionStateChanged: SubscriptionState,
};


export type SubscriptionMessageSentArgs = {
  conversationId: Scalars['Int']
};

export enum SubscriptionState {
  ACTIVE = 'ACTIVE',
  BYPASS = 'BYPASS',
  CANCELLED = 'CANCELLED',
  CANCELLED_PAYMENT_FAILED = 'CANCELLED_PAYMENT_FAILED',
  FREE_TRIAL = 'FREE_TRIAL',
  NONE = 'NONE'
}

export type SwapArticleSectionIndicesInput = {
  articleId: Scalars['Int'],
  firstArticleSectionId: Scalars['Int'],
  secondArticleSectionId: Scalars['Int'],
};

export type SwapArticleSectionIndicesPayload = {
   __typename?: 'SwapArticleSectionIndicesPayload',
  article: Article,
};

export type TextArticleSection = ArticleSection & {
   __typename?: 'TextArticleSection',
  id: Scalars['Int'],
  text: Scalars['String'],
};

export type UnfollowBrandInput = {
  id: Scalars['Int'],
};

export type UnfollowBrandPayload = {
   __typename?: 'UnfollowBrandPayload',
  brand: Brand,
};

export type UpdateArticleHeaderInput = {
  articleId: Scalars['Int'],
  contentType?: Maybe<Scalars['String']>,
  youTubeUrl?: Maybe<Scalars['String']>,
};

export type UpdateArticleHeaderPayload = {
   __typename?: 'UpdateArticleHeaderPayload',
  mediaUploadUrl?: Maybe<Scalars['String']>,
};

export type UpdateArticleInput = {
  articleId: Scalars['Int'],
  title: Scalars['String'],
};

export type UpdateArticlePayload = {
   __typename?: 'UpdateArticlePayload',
  article: Article,
};

export type UpdateBrandInput = {
  brandStory?: Maybe<Scalars['String']>,
  companyType?: Maybe<Scalars['String']>,
  culture?: Maybe<Scalars['String']>,
  headerVideoYouTubeUrl?: Maybe<Scalars['String']>,
  keywordIds?: Maybe<Array<Scalars['Int']>>,
  locations?: Maybe<Array<LocationInput>>,
  name?: Maybe<Scalars['String']>,
  perkIds?: Maybe<Array<Scalars['Int']>>,
  tagLine?: Maybe<Scalars['String']>,
  transportAndAmenities?: Maybe<Scalars['String']>,
};

export type UpdateBrandPayload = {
   __typename?: 'UpdateBrandPayload',
  brand: Brand,
};

export type UpdateContentTargetingInput = {
  id: Scalars['Int'],
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<ContentTargetingLocationInput>,
  skillIds: Array<Scalars['Int']>,
};

export type UpdateContentTargetingPayload = {
   __typename?: 'UpdateContentTargetingPayload',
  content: Content,
};

export type UpdateEmployeeAvatarInput = {
  contentType?: Maybe<Scalars['String']>,
};

export type UpdateEmployeeAvatarPayload = {
   __typename?: 'UpdateEmployeeAvatarPayload',
  uploadExpiry: Scalars['DateTime'],
  uploadUrl: Scalars['String'],
};

export type UpdateEmployeeInput = {
  jobTitle: Scalars['String'],
  name: Scalars['String'],
};

export type UpdateEmployeePayload = {
   __typename?: 'UpdateEmployeePayload',
  employee: Employee,
};

export type UpdatePostInput = {
  id: Scalars['Int'],
  text: Scalars['String'],
};

export type UpdatePostMediaInput = {
  contentType?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  youTubeUrl?: Maybe<Scalars['String']>,
};

export type UpdatePostMediaPayload = {
   __typename?: 'UpdatePostMediaPayload',
  mediaUploadUrl?: Maybe<Scalars['String']>,
};

export type UpdatePostPayload = {
   __typename?: 'UpdatePostPayload',
  post: Post,
};

export type UpdateTextArticleSectionInput = {
  articleId: Scalars['Int'],
  sectionId: Scalars['Int'],
  text: Scalars['String'],
};

export type UpdateTextArticleSectionPayload = {
   __typename?: 'UpdateTextArticleSectionPayload',
  article: Article,
};

export type User = {
   __typename?: 'User',
  accountType: AccountType,
  candidate: Candidate,
  conversation?: Maybe<Conversation>,
  conversations: Array<Conversation>,
  deviceToken?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  employee: Employee,
};


export type UserConversationArgs = {
  id: Scalars['Float']
};

export type TextArticleSectionFragment = (
  { __typename?: 'TextArticleSection' }
  & Pick<TextArticleSection, 'id' | 'text'>
);

export type ResourceArticleSectionFragment = (
  { __typename?: 'ResourceArticleSection' }
  & Pick<ResourceArticleSection, 'id'>
  & { resource: (
    { __typename?: 'Resource' }
    & ResourceFragment
  ) }
);

type ArticleSection_ResourceArticleSection_Fragment = (
  { __typename?: 'ResourceArticleSection' }
  & ResourceArticleSectionFragment
);

type ArticleSection_TextArticleSection_Fragment = (
  { __typename?: 'TextArticleSection' }
  & TextArticleSectionFragment
);

export type ArticleSectionFragment = ArticleSection_ResourceArticleSection_Fragment | ArticleSection_TextArticleSection_Fragment;

export type ArticleFragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'id' | 'title'>
  & { header: Maybe<(
    { __typename?: 'Resource' }
    & ResourceFragment
  )>, sections: Array<(
    { __typename?: 'ResourceArticleSection' }
    & ArticleSection_ResourceArticleSection_Fragment
  ) | (
    { __typename?: 'TextArticleSection' }
    & ArticleSection_TextArticleSection_Fragment
  )> }
);

export type AuthoredArticleFragment = (
  { __typename?: 'Article' }
  & { author: (
    { __typename?: 'Employee' }
    & AuthorFragment
  ) }
  & ArticleFragment
);

export type EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'jobTitle'>
  & { avatar: Maybe<(
    { __typename?: 'Resource' }
    & ResourceFragment
  )> }
);

export type UpdateEmployeeMutationVariables = {
  name: Scalars['String'],
  jobTitle: Scalars['String']
};


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: (
    { __typename?: 'UpdateEmployeePayload' }
    & { employee: (
      { __typename?: 'Employee' }
      & AuthorFragment
    ) }
  ) }
);

export type UpdateEmployeeAvatarMutationVariables = {
  contentType: Scalars['String']
};


export type UpdateEmployeeAvatarMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeeAvatar: (
    { __typename?: 'UpdateEmployeeAvatarPayload' }
    & Pick<UpdateEmployeeAvatarPayload, 'uploadUrl'>
  ) }
);

export type AddBrandLocationMutationVariables = {
  name: Scalars['String'],
  latitude: Scalars['Float'],
  longitude: Scalars['Float']
};


export type AddBrandLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'UpdateBrandPayload' }
    & { brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id'>
      & { locations: Array<(
        { __typename?: 'Location' }
        & LocationFragment
      )> }
    ) }
  ) }
);

export type GetBrandLocationsQueryVariables = {};


export type GetBrandLocationsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & LocationFragment
        )> }
      ) }
    ) }
  ) }
);

export type LocationFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'latitude' | 'longitude'>
);

export type ContentTargetingLocationFragment = (
  { __typename?: 'ContentTargetingLocation' }
  & Pick<ContentTargetingLocation, 'location' | 'latitude' | 'longitude' | 'maxDistance' | 'includeRemoteWorkers'>
);

export type ContentTargetingFragment = (
  { __typename?: 'ContentTargeting' }
  & { skills: Array<(
    { __typename?: 'Skill' }
    & SkillFragment
  )>, industryExperiences: Array<(
    { __typename?: 'IndustryExperience' }
    & IndustryExperienceFragment
  )>, location: Maybe<(
    { __typename?: 'ContentTargetingLocation' }
    & ContentTargetingLocationFragment
  )> }
);

export type UpdateTargetingForContentMutationVariables = {
  id: Scalars['Int'],
  skillIds: Array<Scalars['Int']>,
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<ContentTargetingLocationInput>
};


export type UpdateTargetingForContentMutation = (
  { __typename?: 'Mutation' }
  & { updateContentTargeting: (
    { __typename?: 'UpdateContentTargetingPayload' }
    & { content: (
      { __typename?: 'Article' }
      & { targeting: (
        { __typename?: 'ContentTargeting' }
        & ContentTargetingFragment
      ) }
    ) | (
      { __typename?: 'Post' }
      & { targeting: (
        { __typename?: 'ContentTargeting' }
        & ContentTargetingFragment
      ) }
    ) }
  ) }
);

export type EstimateContentTargetingReachQueryVariables = {
  skillIds: Array<Scalars['Int']>,
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<EstimateContentTargetingReachLocationInput>
};


export type EstimateContentTargetingReachQuery = (
  { __typename?: 'Query' }
  & { estimateContentTargetingReach: (
    { __typename?: 'EstimatedContentTargetingReach' }
    & EstimatedContentTargetingReachFragment
  ) }
);

export type GetTargetingForContentQueryVariables = {
  id: Scalars['Int']
};


export type GetTargetingForContentQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { content: Maybe<(
          { __typename?: 'Article' }
          & Pick<Article, 'id'>
          & { targeting: (
            { __typename?: 'ContentTargeting' }
            & ContentTargetingFragment
          ) }
        ) | (
          { __typename?: 'Post' }
          & Pick<Post, 'id'>
          & { targeting: (
            { __typename?: 'ContentTargeting' }
            & ContentTargetingFragment
          ) }
        )> }
      ) }
    ) }
  ) }
);

export type EstimatedContentTargetingReachFragment = (
  { __typename?: 'EstimatedContentTargetingReach' }
  & Pick<EstimatedContentTargetingReach, 'potentialReach' | 'totalReach'>
  & { estimatedSkillCandidateReaches: Array<(
    { __typename?: 'EstimatedSkillCandidateReach' }
    & Pick<EstimatedSkillCandidateReach, 'reach'>
    & { skill: (
      { __typename?: 'Skill' }
      & SkillFragment
    ) }
  )>, estimatedIndustryExperienceCandidateReaches: Array<(
    { __typename?: 'EstimatedIndustryExperienceCandidateReach' }
    & Pick<EstimatedIndustryExperienceCandidateReach, 'reach'>
    & { industryExperience: (
      { __typename?: 'IndustryExperience' }
      & IndustryExperienceFragment
    ) }
  )>, estimatedLocationCandidateReach: Maybe<(
    { __typename?: 'EstimatedLocationCandidateReach' }
    & Pick<EstimatedLocationCandidateReach, 'location' | 'maxDistance' | 'reach'>
  )> }
);

type TargetedContent_Article_Fragment = (
  { __typename?: 'Article' }
  & { targeting: (
    { __typename?: 'ContentTargeting' }
    & ContentTargetingFragment
  ) }
  & ArticleFragment
);

type TargetedContent_Post_Fragment = (
  { __typename?: 'Post' }
  & { targeting: (
    { __typename?: 'ContentTargeting' }
    & ContentTargetingFragment
  ) }
  & PostFragment
);

export type TargetedContentFragment = TargetedContent_Article_Fragment | TargetedContent_Post_Fragment;

type Content_Article_Fragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'numLikes' | 'numComments' | 'numViews' | 'publishedAt'>
  & { author: (
    { __typename?: 'Employee' }
    & AuthorFragment
  ) }
  & ArticleFragment
);

type Content_Post_Fragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'numLikes' | 'numComments' | 'numViews' | 'publishedAt'>
  & { author: (
    { __typename?: 'Employee' }
    & AuthorFragment
  ) }
  & PostFragment
);

export type ContentFragment = Content_Article_Fragment | Content_Post_Fragment;

export type CreateArticleMutationVariables = {
  title: Scalars['String']
};


export type CreateArticleMutation = (
  { __typename?: 'Mutation' }
  & { createArticle: (
    { __typename?: 'CreateArticlePayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type UpdateArticleMutationVariables = {
  articleId: Scalars['Int'],
  title: Scalars['String']
};


export type UpdateArticleMutation = (
  { __typename?: 'Mutation' }
  & { updateArticle: (
    { __typename?: 'UpdateArticlePayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type UpdateArticleHeaderMutationVariables = {
  articleId: Scalars['Int'],
  contentType?: Maybe<Scalars['String']>,
  youTubeUrl?: Maybe<Scalars['String']>
};


export type UpdateArticleHeaderMutation = (
  { __typename?: 'Mutation' }
  & { updateArticleHeader: (
    { __typename?: 'UpdateArticleHeaderPayload' }
    & Pick<UpdateArticleHeaderPayload, 'mediaUploadUrl'>
  ) }
);

export type SwapArticleSectionIndicesMutationVariables = {
  articleId: Scalars['Int'],
  firstArticleSectionId: Scalars['Int'],
  secondArticleSectionId: Scalars['Int']
};


export type SwapArticleSectionIndicesMutation = (
  { __typename?: 'Mutation' }
  & { swapArticleSectionIndices: (
    { __typename?: 'SwapArticleSectionIndicesPayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type RemoveArticleHeaderMutationVariables = {
  articleId: Scalars['Int']
};


export type RemoveArticleHeaderMutation = (
  { __typename?: 'Mutation' }
  & { removeArticleHeader: (
    { __typename?: 'RemoveArticleHeaderPayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type CreateTextArticleSectionMutationVariables = {
  articleId: Scalars['Int'],
  text: Scalars['String']
};


export type CreateTextArticleSectionMutation = (
  { __typename?: 'Mutation' }
  & { createTextArticleSection: (
    { __typename?: 'CreateTextArticleSectionPayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type UpdateTextArticleSectionMutationVariables = {
  articleId: Scalars['Int'],
  sectionId: Scalars['Int'],
  text: Scalars['String']
};


export type UpdateTextArticleSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateTextArticleSection: (
    { __typename?: 'UpdateTextArticleSectionPayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type CreateResourceArticleSectionMutationVariables = {
  articleId: Scalars['Int'],
  contentType?: Maybe<Scalars['String']>,
  youTubeUrl?: Maybe<Scalars['String']>
};


export type CreateResourceArticleSectionMutation = (
  { __typename?: 'Mutation' }
  & { createResourceArticleSection: (
    { __typename?: 'CreateResourceArticleSectionPayload' }
    & Pick<CreateResourceArticleSectionPayload, 'uploadUrl'>
  ) }
);

export type RemoveArticleSectionMutationVariables = {
  articleId: Scalars['Int'],
  sectionId: Scalars['Int']
};


export type RemoveArticleSectionMutation = (
  { __typename?: 'Mutation' }
  & { removeArticleSection: (
    { __typename?: 'RemoveArticleSectionPayload' }
    & { article: (
      { __typename?: 'Article' }
      & AuthoredArticleFragment
    ) }
  ) }
);

export type CreatePostMutationVariables = {
  text: Scalars['String']
};


export type CreatePostMutation = (
  { __typename?: 'Mutation' }
  & { createPost: (
    { __typename?: 'CreatePostPayload' }
    & { post: (
      { __typename?: 'Post' }
      & AuthoredPostFragment
    ) }
  ) }
);

export type UpdatePostMutationVariables = {
  id: Scalars['Int'],
  text: Scalars['String']
};


export type UpdatePostMutation = (
  { __typename?: 'Mutation' }
  & { updatePost: (
    { __typename?: 'UpdatePostPayload' }
    & { post: (
      { __typename?: 'Post' }
      & AuthoredPostFragment
    ) }
  ) }
);

export type UpdatePostMediaMutationVariables = {
  id: Scalars['Int'],
  contentType?: Maybe<Scalars['String']>,
  youTubeUrl?: Maybe<Scalars['String']>
};


export type UpdatePostMediaMutation = (
  { __typename?: 'Mutation' }
  & { updatePostMedia: (
    { __typename?: 'UpdatePostMediaPayload' }
    & Pick<UpdatePostMediaPayload, 'mediaUploadUrl'>
  ) }
);

export type RemovePostMediaMutationVariables = {
  postId: Scalars['Int']
};


export type RemovePostMediaMutation = (
  { __typename?: 'Mutation' }
  & { removePostMedia: (
    { __typename?: 'RemovePostMediaPayload' }
    & { post: (
      { __typename?: 'Post' }
      & AuthoredPostFragment
    ) }
  ) }
);

export type LikeFragment = (
  { __typename?: 'Like' }
  & Pick<Like, 'id' | 'createdAt'>
  & { liker: (
    { __typename?: 'AnonymousLiker' }
    & Liker_AnonymousLiker_Fragment
  ) | (
    { __typename?: 'NamedLiker' }
    & Liker_NamedLiker_Fragment
  ) }
);

export type LikeActivityFragment = (
  { __typename?: 'Like' }
  & { content: (
    { __typename?: 'Article' }
    & Pick<Article, 'id'>
    & { header: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ) | (
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { media: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ) }
  & LikeFragment
);

export type AnonymousLikerFragment = (
  { __typename?: 'AnonymousLiker' }
  & Pick<AnonymousLiker, 'id'>
);

export type NamedLikerFragment = (
  { __typename?: 'NamedLiker' }
  & Pick<NamedLiker, 'id' | 'name'>
);

type Liker_AnonymousLiker_Fragment = (
  { __typename?: 'AnonymousLiker' }
  & AnonymousLikerFragment
);

type Liker_NamedLiker_Fragment = (
  { __typename?: 'NamedLiker' }
  & NamedLikerFragment
);

export type LikerFragment = Liker_AnonymousLiker_Fragment | Liker_NamedLiker_Fragment;

export type GetLikesOfContentQueryVariables = {
  id: Scalars['Int']
};


export type GetLikesOfContentQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { content: Maybe<(
          { __typename?: 'Article' }
          & Pick<Article, 'numLikes'>
          & { likes: Array<(
            { __typename?: 'Like' }
            & LikeFragment
          )> }
        ) | (
          { __typename?: 'Post' }
          & Pick<Post, 'numLikes'>
          & { likes: Array<(
            { __typename?: 'Like' }
            & LikeFragment
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type PostFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'text'>
  & { media: Maybe<(
    { __typename?: 'Resource' }
    & ResourceFragment
  )> }
);

export type AuthoredPostFragment = (
  { __typename?: 'Post' }
  & { author: (
    { __typename?: 'Employee' }
    & AuthorFragment
  ) }
  & PostFragment
);

export type RemoveContentMutationVariables = {
  contentId: Scalars['Int']
};


export type RemoveContentMutation = (
  { __typename?: 'Mutation' }
  & { removeContent: (
    { __typename?: 'RemoveContentPayload' }
    & Pick<RemoveContentPayload, 'success'>
  ) }
);

export type PublishContentMutationVariables = {
  id: Scalars['Int']
};


export type PublishContentMutation = (
  { __typename?: 'Mutation' }
  & { publishContent: (
    { __typename?: 'PublishContentPayload' }
    & { content: (
      { __typename?: 'Article' }
      & Content_Article_Fragment
    ) | (
      { __typename?: 'Post' }
      & Content_Post_Fragment
    ) }
  ) }
);

export type ContentHubStatsFragment = (
  { __typename?: 'Brand' }
  & Pick<Brand, 'numViews' | 'numFollowers' | 'totalComments' | 'totalViews' | 'totalLikes'>
);

export type GetContentHubStatsQueryVariables = {};


export type GetContentHubStatsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & ContentHubStatsFragment
      ) }
    ) }
  ) }
);

export type GetContentActivityQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int']
};


export type GetContentActivityQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { contentActivity: Array<(
          { __typename?: 'Comment' }
          & CommentActivityFragment
        ) | (
          { __typename?: 'Like' }
          & LikeActivityFragment
        )> }
      ) }
    ) }
  ) }
);

export type GetOwnContentByIdQueryVariables = {
  id: Scalars['Int']
};


export type GetOwnContentByIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { content: Maybe<(
          { __typename?: 'Article' }
          & Content_Article_Fragment
        ) | (
          { __typename?: 'Post' }
          & Content_Post_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type GetOwnPublishedContentQueryVariables = {
  paging: OffsetPagingInput
};


export type GetOwnPublishedContentQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { publishedContent: Array<(
          { __typename?: 'Article' }
          & Content_Article_Fragment
        ) | (
          { __typename?: 'Post' }
          & Content_Post_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type GetOwnDraftContentQueryVariables = {
  paging: OffsetPagingInput
};


export type GetOwnDraftContentQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & { draftContent: Array<(
          { __typename?: 'Article' }
          & Content_Article_Fragment
        ) | (
          { __typename?: 'Post' }
          & Content_Post_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type AuthorFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'jobTitle'>
  & { avatar: Maybe<(
    { __typename?: 'Resource' }
    & ResourceFragment
  )>, brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'name'>
    & { logo: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ) }
);

export type GetAuthorDetailsQueryVariables = {};


export type GetAuthorDetailsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & AuthorFragment
    ) }
  ) }
);

export type CommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'text' | 'createdAt'>
  & { commenter: (
    { __typename?: 'AnonymousCommenter' }
    & Pick<AnonymousCommenter, 'id'>
  ) | (
    { __typename?: 'NamedCommenter' }
    & Pick<NamedCommenter, 'id' | 'name'>
  ) }
);

export type CommentActivityFragment = (
  { __typename?: 'Comment' }
  & { content: (
    { __typename?: 'Article' }
    & Pick<Article, 'id'>
    & { header: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ) | (
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { media: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ) }
  & CommentFragment
);

export type CommentOnContentMutationVariables = {
  contentId: Scalars['Int'],
  text: Scalars['String']
};


export type CommentOnContentMutation = (
  { __typename?: 'Mutation' }
  & { commentOnContent: (
    { __typename?: 'CommentOnContentPayload' }
    & { content: (
      { __typename?: 'Article' }
      & UpdatedCommentsForContent_Article_Fragment
    ) | (
      { __typename?: 'Post' }
      & UpdatedCommentsForContent_Post_Fragment
    ) }
  ) }
);

export type CommentOnCommentMutationVariables = {
  contentId: Scalars['Int'],
  commentId: Scalars['Int'],
  text: Scalars['String']
};


export type CommentOnCommentMutation = (
  { __typename?: 'Mutation' }
  & { commentOnComment: (
    { __typename?: 'CommentOnContentPayload' }
    & { content: (
      { __typename?: 'Article' }
      & UpdatedCommentsForContent_Article_Fragment
    ) | (
      { __typename?: 'Post' }
      & UpdatedCommentsForContent_Post_Fragment
    ) }
  ) }
);

type ContentWithComments_Article_Fragment = (
  { __typename?: 'Article' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
    & CommentFragment
  )> }
  & Content_Article_Fragment
);

type ContentWithComments_Post_Fragment = (
  { __typename?: 'Post' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
    & CommentFragment
  )> }
  & Content_Post_Fragment
);

export type ContentWithCommentsFragment = ContentWithComments_Article_Fragment | ContentWithComments_Post_Fragment;

type UpdatedCommentsForContent_Article_Fragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'id' | 'numComments'>
  & { comments: Array<(
    { __typename?: 'Comment' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
    & CommentFragment
  )> }
);

type UpdatedCommentsForContent_Post_Fragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'numComments'>
  & { comments: Array<(
    { __typename?: 'Comment' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
    & CommentFragment
  )> }
);

export type UpdatedCommentsForContentFragment = UpdatedCommentsForContent_Article_Fragment | UpdatedCommentsForContent_Post_Fragment;

export type GetContentWithCommentsQueryVariables = {
  id: Scalars['Int']
};


export type GetContentWithCommentsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id'>
        & { content: Maybe<(
          { __typename?: 'Article' }
          & ContentWithComments_Article_Fragment
        ) | (
          { __typename?: 'Post' }
          & ContentWithComments_Post_Fragment
        )> }
      ) }
    ) }
  ) }
);

export type IndustryExperienceFragment = (
  { __typename?: 'IndustryExperience' }
  & Pick<IndustryExperience, 'id' | 'name'>
);

export type GetIndustryExperiencesQueryVariables = {};


export type GetIndustryExperiencesQuery = (
  { __typename?: 'Query' }
  & { industryExperiences: Array<(
    { __typename?: 'IndustryExperience' }
    & IndustryExperienceFragment
  )> }
);

export type SkillFragment = (
  { __typename?: 'Skill' }
  & Pick<Skill, 'id' | 'name'>
);

export type ResourceFragment = (
  { __typename?: 'Resource' }
  & Pick<Resource, 'id' | 'type' | 'resource'>
);

type EmployeeNotification_NewContentCommentEmployeeNotification_Fragment = (
  { __typename?: 'NewContentCommentEmployeeNotification' }
  & Pick<NewContentCommentEmployeeNotification, 'id' | 'read' | 'createdAt'>
  & { comment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { content: (
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title'>
    ) | (
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'text'>
    ) }
  ) }
);

type EmployeeNotification_NewInterestedCandidateEmployeeNotification_Fragment = (
  { __typename?: 'NewInterestedCandidateEmployeeNotification' }
  & Pick<NewInterestedCandidateEmployeeNotification, 'id' | 'score' | 'read' | 'createdAt'>
  & { candidate: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'name'>
    & { avatar: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'title'>
  ) }
);

type EmployeeNotification_NewMatchedCandidateEmployeeNotification_Fragment = (
  { __typename?: 'NewMatchedCandidateEmployeeNotification' }
  & Pick<NewMatchedCandidateEmployeeNotification, 'id' | 'score' | 'read' | 'createdAt'>
  & { candidate: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'name'>
    & { avatar: Maybe<(
      { __typename?: 'Resource' }
      & ResourceFragment
    )> }
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'title'>
  ) }
);

type EmployeeNotification_NewMessageEmployeeNotification_Fragment = (
  { __typename?: 'NewMessageEmployeeNotification' }
  & Pick<NewMessageEmployeeNotification, 'id' | 'read' | 'createdAt'>
  & { message: (
    { __typename?: 'Message' }
    & { participant: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'name'>
      & { avatar: Maybe<(
        { __typename?: 'Resource' }
        & ResourceFragment
      )> }
    ) | (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'name'>
      & { avatar: Maybe<(
        { __typename?: 'Resource' }
        & ResourceFragment
      )> }
    ), conversation: (
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'title'>
      ) }
    ) }
  ) }
);

export type EmployeeNotificationFragment = EmployeeNotification_NewContentCommentEmployeeNotification_Fragment | EmployeeNotification_NewInterestedCandidateEmployeeNotification_Fragment | EmployeeNotification_NewMatchedCandidateEmployeeNotification_Fragment | EmployeeNotification_NewMessageEmployeeNotification_Fragment;

export type GetNotificationsQueryVariables = {};


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & { notifications: Array<(
        { __typename?: 'NewContentCommentEmployeeNotification' }
        & EmployeeNotification_NewContentCommentEmployeeNotification_Fragment
      ) | (
        { __typename?: 'NewInterestedCandidateEmployeeNotification' }
        & EmployeeNotification_NewInterestedCandidateEmployeeNotification_Fragment
      ) | (
        { __typename?: 'NewMatchedCandidateEmployeeNotification' }
        & EmployeeNotification_NewMatchedCandidateEmployeeNotification_Fragment
      ) | (
        { __typename?: 'NewMessageEmployeeNotification' }
        & EmployeeNotification_NewMessageEmployeeNotification_Fragment
      )> }
    ) }
  ) }
);

export type NotificationReceivedSubscriptionVariables = {};


export type NotificationReceivedSubscription = (
  { __typename?: 'Subscription' }
  & { notificationReceived: (
    { __typename?: 'NewContentCommentEmployeeNotification' }
    & EmployeeNotification_NewContentCommentEmployeeNotification_Fragment
  ) | (
    { __typename?: 'NewInterestedCandidateEmployeeNotification' }
    & EmployeeNotification_NewInterestedCandidateEmployeeNotification_Fragment
  ) | (
    { __typename?: 'NewMatchedCandidateEmployeeNotification' }
    & EmployeeNotification_NewMatchedCandidateEmployeeNotification_Fragment
  ) | (
    { __typename?: 'NewMessageEmployeeNotification' }
    & EmployeeNotification_NewMessageEmployeeNotification_Fragment
  ) }
);

export type ChargebeeHostedPageFragment = (
  { __typename?: 'ChargebeeHostedPage' }
  & Pick<ChargebeeHostedPage, 'created_at' | 'embed' | 'expires_at' | 'id' | 'object' | 'resource_version' | 'state' | 'type' | 'updated_at' | 'url'>
);

export type ChargebeePortalSessionFragment = (
  { __typename?: 'ChargebeePortalSession' }
  & Pick<ChargebeePortalSession, 'id' | 'token' | 'access_url' | 'status' | 'created_at' | 'expires_at' | 'object' | 'customer_id'>
  & { linked_customers: Array<(
    { __typename?: 'ChargebeeLinkedCustomer' }
    & Pick<ChargebeeLinkedCustomer, 'object' | 'customer_id' | 'email' | 'has_billing_address' | 'has_payment_method' | 'has_active_subscription'>
  )> }
);

export type CreateHostedPageMutationVariables = {
  type: HostedPageType,
  plan?: Maybe<Plan>
};


export type CreateHostedPageMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeeHostedPage: (
    { __typename?: 'ChargebeeHostedPage' }
    & ChargebeeHostedPageFragment
  ) }
);

export type CreatePortalSessionMutationVariables = {};


export type CreatePortalSessionMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeePortalSession: (
    { __typename?: 'ChargebeePortalSession' }
    & ChargebeePortalSessionFragment
  ) }
);

export type CreateOfflineSubscriptionMutationVariables = {
  plan: Plan
};


export type CreateOfflineSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createOfflineSubscription: (
    { __typename?: 'CreateOfflineSubscriptionPayload' }
    & Pick<CreateOfflineSubscriptionPayload, 'success'>
  ) }
);

export type ReactivateOfflineSubscriptionMutationVariables = {};


export type ReactivateOfflineSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { reactivateOfflineSubscription: (
    { __typename?: 'ReactivateOfflineSubscriptionPayload' }
    & Pick<ReactivateOfflineSubscriptionPayload, 'success'>
  ) }
);

export type RequestExternalPaymentMutationVariables = {
  input: RequestExternalPaymentInput
};


export type RequestExternalPaymentMutation = (
  { __typename?: 'Mutation' }
  & { requestExternalPayment: (
    { __typename?: 'RequestExternalPaymentPayload' }
    & Pick<RequestExternalPaymentPayload, 'success'>
  ) }
);

export type CreateChargebeeHostedPageForExternalFinancierMutationVariables = {
  input: CreateChargebeeHostedPageForExternalFinancierInput
};


export type CreateChargebeeHostedPageForExternalFinancierMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeeHostedPageForExternalFinancier: (
    { __typename?: 'ChargebeeHostedPage' }
    & ChargebeeHostedPageFragment
  ) }
);

export type CreateChargebeePortalSessionForExternalFinancierMutationVariables = {
  input: CreateChargebeePortalSessionForExternalFinancierInput
};


export type CreateChargebeePortalSessionForExternalFinancierMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeePortalSessionForExternalFinancier: (
    { __typename?: 'ChargebeePortalSession' }
    & ChargebeePortalSessionFragment
  ) }
);

export type NotifyEmployeeOfExternalPaymentMutationVariables = {
  input: NotifyEmployeeOfExternalPaymentInput
};


export type NotifyEmployeeOfExternalPaymentMutation = (
  { __typename?: 'Mutation' }
  & { notifyEmployeeOfExternalPayment: (
    { __typename?: 'NotifyEmployeeOfExternalPaymentPayload' }
    & Pick<NotifyEmployeeOfExternalPaymentPayload, 'success'>
  ) }
);

export type GetBrandSubscriptionStateQueryVariables = {};


export type GetBrandSubscriptionStateQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { employee: (
      { __typename?: 'Employee' }
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'subscriptionState'>
      ) }
    ) }
  ) }
);

export type GetExternalFinancierSubscriptionStateQueryVariables = {
  token: Scalars['String']
};


export type GetExternalFinancierSubscriptionStateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'externalFinancierSubscriptionState'>
);

export type SubscriptionStateChangedSubscriptionVariables = {};


export type SubscriptionStateChangedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'subscriptionStateChanged'>
);

export const ResourceFragmentDoc = gql`
    fragment Resource on Resource {
  id
  type
  resource
}
    `;
export const ResourceArticleSectionFragmentDoc = gql`
    fragment ResourceArticleSection on ResourceArticleSection {
  id
  resource {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const TextArticleSectionFragmentDoc = gql`
    fragment TextArticleSection on TextArticleSection {
  id
  text
}
    `;
export const ArticleSectionFragmentDoc = gql`
    fragment ArticleSection on ArticleSection {
  ...ResourceArticleSection
  ...TextArticleSection
}
    ${ResourceArticleSectionFragmentDoc}
${TextArticleSectionFragmentDoc}`;
export const ArticleFragmentDoc = gql`
    fragment Article on Article {
  id
  title
  header {
    ...Resource
  }
  sections {
    ...ArticleSection
  }
}
    ${ResourceFragmentDoc}
${ArticleSectionFragmentDoc}`;
export const AuthorFragmentDoc = gql`
    fragment Author on Employee {
  id
  name
  jobTitle
  avatar {
    ...Resource
  }
  brand {
    name
    logo {
      ...Resource
    }
  }
}
    ${ResourceFragmentDoc}`;
export const AuthoredArticleFragmentDoc = gql`
    fragment AuthoredArticle on Article {
  ...Article
  author {
    ...Author
  }
}
    ${ArticleFragmentDoc}
${AuthorFragmentDoc}`;
export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  id
  name
  jobTitle
  avatar {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  name
  latitude
  longitude
}
    `;
export const SkillFragmentDoc = gql`
    fragment Skill on Skill {
  id
  name
}
    `;
export const IndustryExperienceFragmentDoc = gql`
    fragment IndustryExperience on IndustryExperience {
  id
  name
}
    `;
export const EstimatedContentTargetingReachFragmentDoc = gql`
    fragment EstimatedContentTargetingReach on EstimatedContentTargetingReach {
  potentialReach
  estimatedSkillCandidateReaches {
    skill {
      ...Skill
    }
    reach
  }
  estimatedIndustryExperienceCandidateReaches {
    industryExperience {
      ...IndustryExperience
    }
    reach
  }
  estimatedLocationCandidateReach {
    location
    maxDistance
    reach
  }
  totalReach
}
    ${SkillFragmentDoc}
${IndustryExperienceFragmentDoc}`;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  text
  media {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const ContentTargetingLocationFragmentDoc = gql`
    fragment ContentTargetingLocation on ContentTargetingLocation {
  location
  latitude
  longitude
  maxDistance
  includeRemoteWorkers
}
    `;
export const ContentTargetingFragmentDoc = gql`
    fragment ContentTargeting on ContentTargeting {
  skills {
    ...Skill
  }
  industryExperiences {
    ...IndustryExperience
  }
  location {
    ...ContentTargetingLocation
  }
}
    ${SkillFragmentDoc}
${IndustryExperienceFragmentDoc}
${ContentTargetingLocationFragmentDoc}`;
export const TargetedContentFragmentDoc = gql`
    fragment TargetedContent on Content {
  ...Article
  ...Post
  targeting {
    ...ContentTargeting
  }
}
    ${ArticleFragmentDoc}
${PostFragmentDoc}
${ContentTargetingFragmentDoc}`;
export const AnonymousLikerFragmentDoc = gql`
    fragment AnonymousLiker on AnonymousLiker {
  id
}
    `;
export const NamedLikerFragmentDoc = gql`
    fragment NamedLiker on NamedLiker {
  id
  name
}
    `;
export const LikerFragmentDoc = gql`
    fragment Liker on Liker {
  ...AnonymousLiker
  ...NamedLiker
}
    ${AnonymousLikerFragmentDoc}
${NamedLikerFragmentDoc}`;
export const LikeFragmentDoc = gql`
    fragment Like on Like {
  id
  liker {
    ...Liker
  }
  createdAt
}
    ${LikerFragmentDoc}`;
export const LikeActivityFragmentDoc = gql`
    fragment LikeActivity on Like {
  ...Like
  content {
    id
    ... on Article {
      header {
        ...Resource
      }
    }
    ... on Post {
      media {
        ...Resource
      }
    }
  }
}
    ${LikeFragmentDoc}
${ResourceFragmentDoc}`;
export const AuthoredPostFragmentDoc = gql`
    fragment AuthoredPost on Post {
  ...Post
  author {
    ...Author
  }
}
    ${PostFragmentDoc}
${AuthorFragmentDoc}`;
export const ContentHubStatsFragmentDoc = gql`
    fragment ContentHubStats on Brand {
  numViews
  numFollowers
  totalComments
  totalViews
  totalLikes
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  text
  commenter {
    ... on NamedCommenter {
      id
      name
    }
    ... on AnonymousCommenter {
      id
    }
  }
  createdAt
}
    `;
export const CommentActivityFragmentDoc = gql`
    fragment CommentActivity on Comment {
  ...Comment
  content {
    id
    ... on Article {
      header {
        ...Resource
      }
    }
    ... on Post {
      media {
        ...Resource
      }
    }
  }
}
    ${CommentFragmentDoc}
${ResourceFragmentDoc}`;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  ...Article
  ...Post
  author {
    ...Author
  }
  numLikes
  numComments
  numViews
  publishedAt
}
    ${ArticleFragmentDoc}
${PostFragmentDoc}
${AuthorFragmentDoc}`;
export const ContentWithCommentsFragmentDoc = gql`
    fragment ContentWithComments on Content {
  ...Content
  comments {
    ...Comment
    comments {
      ...Comment
    }
  }
}
    ${ContentFragmentDoc}
${CommentFragmentDoc}`;
export const UpdatedCommentsForContentFragmentDoc = gql`
    fragment UpdatedCommentsForContent on Content {
  id
  numComments
  comments {
    ...Comment
    comments {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;
export const EmployeeNotificationFragmentDoc = gql`
    fragment EmployeeNotification on EmployeeNotification {
  id
  read
  createdAt
  ... on NewContentCommentEmployeeNotification {
    id
    comment {
      id
      content {
        ... on Article {
          id
          title
        }
        ... on Post {
          id
          text
        }
      }
    }
  }
  ... on NewInterestedCandidateEmployeeNotification {
    id
    candidate {
      id
      avatar {
        ...Resource
      }
      name
    }
    role {
      id
      title
    }
    score
  }
  ... on NewMatchedCandidateEmployeeNotification {
    id
    candidate {
      id
      avatar {
        ...Resource
      }
      name
    }
    role {
      id
      title
    }
    score
  }
  ... on NewMessageEmployeeNotification {
    id
    message {
      participant {
        avatar {
          ...Resource
        }
        name
      }
      conversation {
        id
        role {
          id
          title
        }
      }
    }
  }
}
    ${ResourceFragmentDoc}`;
export const ChargebeeHostedPageFragmentDoc = gql`
    fragment ChargebeeHostedPage on ChargebeeHostedPage {
  created_at
  embed
  expires_at
  id
  object
  resource_version
  state
  type
  updated_at
  url
}
    `;
export const ChargebeePortalSessionFragmentDoc = gql`
    fragment ChargebeePortalSession on ChargebeePortalSession {
  id
  token
  access_url
  status
  created_at
  expires_at
  object
  customer_id
  linked_customers {
    object
    customer_id
    email
    has_billing_address
    has_payment_method
    has_active_subscription
  }
}
    `;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($name: String!, $jobTitle: String!) {
  updateEmployee(input: {name: $name, jobTitle: $jobTitle}) {
    employee {
      ...Author
    }
  }
}
    ${AuthorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeGQL extends Apollo.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> {
    document = UpdateEmployeeDocument;
    
  }
export const UpdateEmployeeAvatarDocument = gql`
    mutation UpdateEmployeeAvatar($contentType: String!) {
  updateEmployeeAvatar(input: {contentType: $contentType}) {
    uploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeAvatarGQL extends Apollo.Mutation<UpdateEmployeeAvatarMutation, UpdateEmployeeAvatarMutationVariables> {
    document = UpdateEmployeeAvatarDocument;
    
  }
export const AddBrandLocationDocument = gql`
    mutation AddBrandLocation($name: String!, $latitude: Float!, $longitude: Float!) {
  updateBrand(input: {locations: [{name: $name, latitude: $latitude, longitude: $longitude}]}) {
    brand {
      id
      locations {
        ...Location
      }
    }
  }
}
    ${LocationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBrandLocationGQL extends Apollo.Mutation<AddBrandLocationMutation, AddBrandLocationMutationVariables> {
    document = AddBrandLocationDocument;
    
  }
export const GetBrandLocationsDocument = gql`
    query GetBrandLocations {
  me {
    employee {
      brand {
        id
        locations {
          ...Location
        }
      }
    }
  }
}
    ${LocationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrandLocationsGQL extends Apollo.Query<GetBrandLocationsQuery, GetBrandLocationsQueryVariables> {
    document = GetBrandLocationsDocument;
    
  }
export const UpdateTargetingForContentDocument = gql`
    mutation UpdateTargetingForContent($id: Int!, $skillIds: [Int!]!, $industryExperienceIds: [Int!]!, $location: ContentTargetingLocationInput) {
  updateContentTargeting(input: {id: $id, skillIds: $skillIds, industryExperienceIds: $industryExperienceIds, location: $location}) {
    content {
      targeting {
        ...ContentTargeting
      }
    }
  }
}
    ${ContentTargetingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTargetingForContentGQL extends Apollo.Mutation<UpdateTargetingForContentMutation, UpdateTargetingForContentMutationVariables> {
    document = UpdateTargetingForContentDocument;
    
  }
export const EstimateContentTargetingReachDocument = gql`
    query EstimateContentTargetingReach($skillIds: [Int!]!, $industryExperienceIds: [Int!]!, $location: EstimateContentTargetingReachLocationInput) {
  estimateContentTargetingReach(input: {skillIds: $skillIds, industryExperienceIds: $industryExperienceIds, location: $location}) {
    ...EstimatedContentTargetingReach
  }
}
    ${EstimatedContentTargetingReachFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EstimateContentTargetingReachGQL extends Apollo.Query<EstimateContentTargetingReachQuery, EstimateContentTargetingReachQueryVariables> {
    document = EstimateContentTargetingReachDocument;
    
  }
export const GetTargetingForContentDocument = gql`
    query GetTargetingForContent($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          id
          targeting {
            ...ContentTargeting
          }
        }
      }
    }
  }
}
    ${ContentTargetingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTargetingForContentGQL extends Apollo.Query<GetTargetingForContentQuery, GetTargetingForContentQueryVariables> {
    document = GetTargetingForContentDocument;
    
  }
export const CreateArticleDocument = gql`
    mutation CreateArticle($title: String!) {
  createArticle(input: {title: $title}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateArticleGQL extends Apollo.Mutation<CreateArticleMutation, CreateArticleMutationVariables> {
    document = CreateArticleDocument;
    
  }
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($articleId: Int!, $title: String!) {
  updateArticle(input: {articleId: $articleId, title: $title}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateArticleGQL extends Apollo.Mutation<UpdateArticleMutation, UpdateArticleMutationVariables> {
    document = UpdateArticleDocument;
    
  }
export const UpdateArticleHeaderDocument = gql`
    mutation UpdateArticleHeader($articleId: Int!, $contentType: String, $youTubeUrl: String) {
  updateArticleHeader(input: {articleId: $articleId, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    mediaUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateArticleHeaderGQL extends Apollo.Mutation<UpdateArticleHeaderMutation, UpdateArticleHeaderMutationVariables> {
    document = UpdateArticleHeaderDocument;
    
  }
export const SwapArticleSectionIndicesDocument = gql`
    mutation SwapArticleSectionIndices($articleId: Int!, $firstArticleSectionId: Int!, $secondArticleSectionId: Int!) {
  swapArticleSectionIndices(input: {articleId: $articleId, firstArticleSectionId: $firstArticleSectionId, secondArticleSectionId: $secondArticleSectionId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SwapArticleSectionIndicesGQL extends Apollo.Mutation<SwapArticleSectionIndicesMutation, SwapArticleSectionIndicesMutationVariables> {
    document = SwapArticleSectionIndicesDocument;
    
  }
export const RemoveArticleHeaderDocument = gql`
    mutation RemoveArticleHeader($articleId: Int!) {
  removeArticleHeader(input: {articleId: $articleId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveArticleHeaderGQL extends Apollo.Mutation<RemoveArticleHeaderMutation, RemoveArticleHeaderMutationVariables> {
    document = RemoveArticleHeaderDocument;
    
  }
export const CreateTextArticleSectionDocument = gql`
    mutation CreateTextArticleSection($articleId: Int!, $text: String!) {
  createTextArticleSection(input: {articleId: $articleId, text: $text}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTextArticleSectionGQL extends Apollo.Mutation<CreateTextArticleSectionMutation, CreateTextArticleSectionMutationVariables> {
    document = CreateTextArticleSectionDocument;
    
  }
export const UpdateTextArticleSectionDocument = gql`
    mutation UpdateTextArticleSection($articleId: Int!, $sectionId: Int!, $text: String!) {
  updateTextArticleSection(input: {articleId: $articleId, sectionId: $sectionId, text: $text}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextArticleSectionGQL extends Apollo.Mutation<UpdateTextArticleSectionMutation, UpdateTextArticleSectionMutationVariables> {
    document = UpdateTextArticleSectionDocument;
    
  }
export const CreateResourceArticleSectionDocument = gql`
    mutation CreateResourceArticleSection($articleId: Int!, $contentType: String, $youTubeUrl: String) {
  createResourceArticleSection(input: {articleId: $articleId, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    uploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateResourceArticleSectionGQL extends Apollo.Mutation<CreateResourceArticleSectionMutation, CreateResourceArticleSectionMutationVariables> {
    document = CreateResourceArticleSectionDocument;
    
  }
export const RemoveArticleSectionDocument = gql`
    mutation RemoveArticleSection($articleId: Int!, $sectionId: Int!) {
  removeArticleSection(input: {articleId: $articleId, sectionId: $sectionId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveArticleSectionGQL extends Apollo.Mutation<RemoveArticleSectionMutation, RemoveArticleSectionMutationVariables> {
    document = RemoveArticleSectionDocument;
    
  }
export const CreatePostDocument = gql`
    mutation CreatePost($text: String!) {
  createPost(input: {text: $text}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePostGQL extends Apollo.Mutation<CreatePostMutation, CreatePostMutationVariables> {
    document = CreatePostDocument;
    
  }
export const UpdatePostDocument = gql`
    mutation UpdatePost($id: Int!, $text: String!) {
  updatePost(input: {id: $id, text: $text}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePostGQL extends Apollo.Mutation<UpdatePostMutation, UpdatePostMutationVariables> {
    document = UpdatePostDocument;
    
  }
export const UpdatePostMediaDocument = gql`
    mutation UpdatePostMedia($id: Int!, $contentType: String, $youTubeUrl: String) {
  updatePostMedia(input: {id: $id, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    mediaUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePostMediaGQL extends Apollo.Mutation<UpdatePostMediaMutation, UpdatePostMediaMutationVariables> {
    document = UpdatePostMediaDocument;
    
  }
export const RemovePostMediaDocument = gql`
    mutation RemovePostMedia($postId: Int!) {
  removePostMedia(input: {postId: $postId}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePostMediaGQL extends Apollo.Mutation<RemovePostMediaMutation, RemovePostMediaMutationVariables> {
    document = RemovePostMediaDocument;
    
  }
export const GetLikesOfContentDocument = gql`
    query GetLikesOfContent($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          numLikes
          ... on Article {
            likes(paging: {offset: 0, limit: 8}) {
              ...Like
            }
          }
          ... on Post {
            likes(paging: {offset: 0, limit: 8}) {
              ...Like
            }
          }
        }
      }
    }
  }
}
    ${LikeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLikesOfContentGQL extends Apollo.Query<GetLikesOfContentQuery, GetLikesOfContentQueryVariables> {
    document = GetLikesOfContentDocument;
    
  }
export const RemoveContentDocument = gql`
    mutation RemoveContent($contentId: Int!) {
  removeContent(input: {contentId: $contentId}) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveContentGQL extends Apollo.Mutation<RemoveContentMutation, RemoveContentMutationVariables> {
    document = RemoveContentDocument;
    
  }
export const PublishContentDocument = gql`
    mutation PublishContent($id: Int!) {
  publishContent(input: {id: $id}) {
    content {
      ...Content
    }
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishContentGQL extends Apollo.Mutation<PublishContentMutation, PublishContentMutationVariables> {
    document = PublishContentDocument;
    
  }
export const GetContentHubStatsDocument = gql`
    query GetContentHubStats {
  me {
    employee {
      brand {
        ...ContentHubStats
      }
    }
  }
}
    ${ContentHubStatsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContentHubStatsGQL extends Apollo.Query<GetContentHubStatsQuery, GetContentHubStatsQueryVariables> {
    document = GetContentHubStatsDocument;
    
  }
export const GetContentActivityDocument = gql`
    query GetContentActivity($offset: Int!, $limit: Int!) {
  me {
    employee {
      brand {
        contentActivity(paging: {offset: $offset, limit: $limit}) {
          ...CommentActivity
          ...LikeActivity
        }
      }
    }
  }
}
    ${CommentActivityFragmentDoc}
${LikeActivityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContentActivityGQL extends Apollo.Query<GetContentActivityQuery, GetContentActivityQueryVariables> {
    document = GetContentActivityDocument;
    
  }
export const GetOwnContentByIdDocument = gql`
    query GetOwnContentById($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOwnContentByIdGQL extends Apollo.Query<GetOwnContentByIdQuery, GetOwnContentByIdQueryVariables> {
    document = GetOwnContentByIdDocument;
    
  }
export const GetOwnPublishedContentDocument = gql`
    query GetOwnPublishedContent($paging: OffsetPagingInput!) {
  me {
    employee {
      brand {
        publishedContent(paging: $paging) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOwnPublishedContentGQL extends Apollo.Query<GetOwnPublishedContentQuery, GetOwnPublishedContentQueryVariables> {
    document = GetOwnPublishedContentDocument;
    
  }
export const GetOwnDraftContentDocument = gql`
    query GetOwnDraftContent($paging: OffsetPagingInput!) {
  me {
    employee {
      brand {
        draftContent(paging: $paging) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOwnDraftContentGQL extends Apollo.Query<GetOwnDraftContentQuery, GetOwnDraftContentQueryVariables> {
    document = GetOwnDraftContentDocument;
    
  }
export const GetAuthorDetailsDocument = gql`
    query GetAuthorDetails {
  me {
    employee {
      ...Author
    }
  }
}
    ${AuthorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAuthorDetailsGQL extends Apollo.Query<GetAuthorDetailsQuery, GetAuthorDetailsQueryVariables> {
    document = GetAuthorDetailsDocument;
    
  }
export const CommentOnContentDocument = gql`
    mutation CommentOnContent($contentId: Int!, $text: String!) {
  commentOnContent(input: {contentId: $contentId, text: $text}) {
    content {
      ...UpdatedCommentsForContent
    }
  }
}
    ${UpdatedCommentsForContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CommentOnContentGQL extends Apollo.Mutation<CommentOnContentMutation, CommentOnContentMutationVariables> {
    document = CommentOnContentDocument;
    
  }
export const CommentOnCommentDocument = gql`
    mutation CommentOnComment($contentId: Int!, $commentId: Int!, $text: String!) {
  commentOnComment(input: {contentId: $contentId, commentId: $commentId, text: $text}) {
    content {
      ...UpdatedCommentsForContent
    }
  }
}
    ${UpdatedCommentsForContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CommentOnCommentGQL extends Apollo.Mutation<CommentOnCommentMutation, CommentOnCommentMutationVariables> {
    document = CommentOnCommentDocument;
    
  }
export const GetContentWithCommentsDocument = gql`
    query GetContentWithComments($id: Int!) {
  me {
    employee {
      id
      brand {
        id
        content(id: $id) {
          ...ContentWithComments
        }
      }
    }
  }
}
    ${ContentWithCommentsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContentWithCommentsGQL extends Apollo.Query<GetContentWithCommentsQuery, GetContentWithCommentsQueryVariables> {
    document = GetContentWithCommentsDocument;
    
  }
export const GetIndustryExperiencesDocument = gql`
    query GetIndustryExperiences {
  industryExperiences {
    ...IndustryExperience
  }
}
    ${IndustryExperienceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIndustryExperiencesGQL extends Apollo.Query<GetIndustryExperiencesQuery, GetIndustryExperiencesQueryVariables> {
    document = GetIndustryExperiencesDocument;
    
  }
export const GetNotificationsDocument = gql`
    query GetNotifications {
  me {
    employee {
      id
      notifications {
        ...EmployeeNotification
      }
    }
  }
}
    ${EmployeeNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationsGQL extends Apollo.Query<GetNotificationsQuery, GetNotificationsQueryVariables> {
    document = GetNotificationsDocument;
    
  }
export const NotificationReceivedDocument = gql`
    subscription NotificationReceived {
  notificationReceived {
    ...EmployeeNotification
  }
}
    ${EmployeeNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationReceivedGQL extends Apollo.Subscription<NotificationReceivedSubscription, NotificationReceivedSubscriptionVariables> {
    document = NotificationReceivedDocument;
    
  }
export const CreateHostedPageDocument = gql`
    mutation CreateHostedPage($type: HostedPageType!, $plan: Plan) {
  createChargebeeHostedPage(input: {type: $type, plan: $plan}) {
    ...ChargebeeHostedPage
  }
}
    ${ChargebeeHostedPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHostedPageGQL extends Apollo.Mutation<CreateHostedPageMutation, CreateHostedPageMutationVariables> {
    document = CreateHostedPageDocument;
    
  }
export const CreatePortalSessionDocument = gql`
    mutation CreatePortalSession {
  createChargebeePortalSession {
    ...ChargebeePortalSession
  }
}
    ${ChargebeePortalSessionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePortalSessionGQL extends Apollo.Mutation<CreatePortalSessionMutation, CreatePortalSessionMutationVariables> {
    document = CreatePortalSessionDocument;
    
  }
export const CreateOfflineSubscriptionDocument = gql`
    mutation CreateOfflineSubscription($plan: Plan!) {
  createOfflineSubscription(input: {plan: $plan}) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfflineSubscriptionGQL extends Apollo.Mutation<CreateOfflineSubscriptionMutation, CreateOfflineSubscriptionMutationVariables> {
    document = CreateOfflineSubscriptionDocument;
    
  }
export const ReactivateOfflineSubscriptionDocument = gql`
    mutation ReactivateOfflineSubscription {
  reactivateOfflineSubscription {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReactivateOfflineSubscriptionGQL extends Apollo.Mutation<ReactivateOfflineSubscriptionMutation, ReactivateOfflineSubscriptionMutationVariables> {
    document = ReactivateOfflineSubscriptionDocument;
    
  }
export const RequestExternalPaymentDocument = gql`
    mutation RequestExternalPayment($input: RequestExternalPaymentInput!) {
  requestExternalPayment(input: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestExternalPaymentGQL extends Apollo.Mutation<RequestExternalPaymentMutation, RequestExternalPaymentMutationVariables> {
    document = RequestExternalPaymentDocument;
    
  }
export const CreateChargebeeHostedPageForExternalFinancierDocument = gql`
    mutation CreateChargebeeHostedPageForExternalFinancier($input: CreateChargebeeHostedPageForExternalFinancierInput!) {
  createChargebeeHostedPageForExternalFinancier(input: $input) {
    ...ChargebeeHostedPage
  }
}
    ${ChargebeeHostedPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChargebeeHostedPageForExternalFinancierGQL extends Apollo.Mutation<CreateChargebeeHostedPageForExternalFinancierMutation, CreateChargebeeHostedPageForExternalFinancierMutationVariables> {
    document = CreateChargebeeHostedPageForExternalFinancierDocument;
    
  }
export const CreateChargebeePortalSessionForExternalFinancierDocument = gql`
    mutation CreateChargebeePortalSessionForExternalFinancier($input: CreateChargebeePortalSessionForExternalFinancierInput!) {
  createChargebeePortalSessionForExternalFinancier(input: $input) {
    ...ChargebeePortalSession
  }
}
    ${ChargebeePortalSessionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChargebeePortalSessionForExternalFinancierGQL extends Apollo.Mutation<CreateChargebeePortalSessionForExternalFinancierMutation, CreateChargebeePortalSessionForExternalFinancierMutationVariables> {
    document = CreateChargebeePortalSessionForExternalFinancierDocument;
    
  }
export const NotifyEmployeeOfExternalPaymentDocument = gql`
    mutation NotifyEmployeeOfExternalPayment($input: NotifyEmployeeOfExternalPaymentInput!) {
  notifyEmployeeOfExternalPayment(input: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NotifyEmployeeOfExternalPaymentGQL extends Apollo.Mutation<NotifyEmployeeOfExternalPaymentMutation, NotifyEmployeeOfExternalPaymentMutationVariables> {
    document = NotifyEmployeeOfExternalPaymentDocument;
    
  }
export const GetBrandSubscriptionStateDocument = gql`
    query GetBrandSubscriptionState {
  me {
    employee {
      brand {
        subscriptionState
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrandSubscriptionStateGQL extends Apollo.Query<GetBrandSubscriptionStateQuery, GetBrandSubscriptionStateQueryVariables> {
    document = GetBrandSubscriptionStateDocument;
    
  }
export const GetExternalFinancierSubscriptionStateDocument = gql`
    query GetExternalFinancierSubscriptionState($token: String!) {
  externalFinancierSubscriptionState(input: {token: $token})
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExternalFinancierSubscriptionStateGQL extends Apollo.Query<GetExternalFinancierSubscriptionStateQuery, GetExternalFinancierSubscriptionStateQueryVariables> {
    document = GetExternalFinancierSubscriptionStateDocument;
    
  }
export const SubscriptionStateChangedDocument = gql`
    subscription SubscriptionStateChanged {
  subscriptionStateChanged
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionStateChangedGQL extends Apollo.Subscription<SubscriptionStateChangedSubscription, SubscriptionStateChangedSubscriptionVariables> {
    document = SubscriptionStateChangedDocument;
    
  }