<ng-container>
	<img src="assets/images/offline-payments.svg" alt="Offline Payments"/>
	<h2>Set up your plan</h2>
	<p>An email will be sent to you with the Caroo bank details so you can subscribe and pay. It’s only £399+vat per month or £2000+vat per year for unlimited use.</p>
	<p>If payment is not received within 7 days, your account will be suspended until payment is made.</p>
	<caroo-subscription-plan-options [(selectedPlan)]="selectedPlan"></caroo-subscription-plan-options>
	<label *ngIf="isEmailSent else setupSubscription" class="email-sent">
		<mat-icon>done</mat-icon>
		Email Sent!
	</label>
	<ng-template #setupSubscription>
		<button caroo-primary-button (click)="setupOfflineSubscription(selectedPlan)" [disabled]="!selectedPlan">Proceed</button>
	</ng-template>
</ng-container>
