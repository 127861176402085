<ng-container>
	<div class="option">
		<label>Pay Monthly</label>
		<label>£399+vat</label>
		<label>Per Month</label>
		<button class="cta primary" *ngIf="selectedPlan === Plan.MONTHLY; else monthly">Selected</button>
		<ng-template #monthly>
			<button class="cta secondary" mat-ripple (click)="selectPlan(Plan.MONTHLY)">Select</button>
		</ng-template>
	</div>
	<div class="option">
		<label>Pay Yearly</label>
		<label>£2000+vat</label>
		<label>Per Year</label>
		<div class="label">
			<div></div>
			<label>2 months free</label>
			<div></div>
		</div>
		<button class="cta primary" *ngIf="selectedPlan === Plan.YEARLY; else yearly">Selected</button>
		<ng-template #yearly>
			<button class="cta secondary" mat-ripple (click)="selectPlan(Plan.YEARLY)">Select</button>
		</ng-template>
	</div>
</ng-container>
