import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {SubscriptionPlanOptionsComponent} from '@caroo/payment/subscription-plan-options/subscription-plan-options.component';

@NgModule({
	imports: [MatRippleModule, CommonModule],
	declarations: [SubscriptionPlanOptionsComponent],
	exports: [SubscriptionPlanOptionsComponent]
})
export class SubscriptionPlanOptionsModule {
}
