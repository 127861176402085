import {map} from 'rxjs/operators';
import {Plan, SubscriptionState} from '../../../generated/graphql';

export function isSubscriptionActive(subscriptionState?: SubscriptionState): boolean {
	if (subscriptionState) {
		switch (subscriptionState) {
			case SubscriptionState.ACTIVE:
			case SubscriptionState.FREE_TRIAL:
			case SubscriptionState.BYPASS:
				return true;
			case SubscriptionState.CANCELLED:
			case SubscriptionState.CANCELLED_PAYMENT_FAILED:
			case SubscriptionState.NONE:
				return false;
		}
	} else {
		return false;
	}
}

export function isSubscriptionCancelled(subscriptionState?: SubscriptionState): boolean {
	if (subscriptionState) {
		switch (subscriptionState) {
			case SubscriptionState.CANCELLED:
			case SubscriptionState.CANCELLED_PAYMENT_FAILED:
				return true;
			case SubscriptionState.ACTIVE:
			case SubscriptionState.FREE_TRIAL:
			case SubscriptionState.NONE:
			case SubscriptionState.BYPASS:
				return false;
		}
	} else {
		return false;
	}
}

export const mapSubscriptionStateToIsActive = map((subscriptionState: SubscriptionState) => isSubscriptionActive(subscriptionState));

export function getPlanText(plan: Plan): string {
	switch (plan) {
		case Plan.MONTHLY:
			return 'Monthly £399+VAT/month';
		case Plan.QUARTERLY:
			return 'Quarterly £199+VAT/month';
		case Plan.YEARLY:
			return 'Yearly £167+VAT/month';
		case Plan.FULL_SERVICE:
			return 'Full Service £250+VAT/month';
		default:
			throw new Error(`Unknown plan: ${plan}`);
	}
}
