<ng-container>
	<div class="options">
		<div class="option-wrapper">
			<div class="card option">
				<h3>Standard (Quarterly)</h3>
				<div class="option-content">
					<ul>
						<li>Includes profile setup</li>
						<li>Unlimited job ads</li>
						<li>Unlimited hires</li>
						<li>Unlimited users</li>
						<li>3 months unrestricted access</li>
					</ul>
					<label class="price">£199</label>
					<label class="period">Paid quarterly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.QUARTERLY)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.QUARTERLY)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
		<div class="option-wrapper">
			<div class="card option">
				<h3>Standard (Annual)</h3>
				<div class="option-content">
					<ul>
						<li>Everything included in <strong>Standard (Quarterly)</strong></li>
						<li>12 months unrestricted access</li>
						<li>Heavily discounted rate</li>
					</ul>
					<label class="price">£167</label>
					<label class="period">Paid yearly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.YEARLY)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.YEARLY)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
		<div class="option-wrapper full-service">
			<div class="card option">
				<h3>Full Service</h3>
				<div class="option-content">
					<ul>
						<li>Everything included in <strong>Standard (Annual)</strong></li>
						<li>Dedicated account manager</li>
						<li>Administrative support</li>
						<li>Support posting jobs</li>
						<li>Recruitment advice</li>
						<li>Available on invoice</li>
						<li>Co-branded ads</li>
						<li>12 months unrestricted access</li>
					</ul>
					<label class="price">£250</label>
					<label class="period">Paid yearly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.FULL_SERVICE)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.FULL_SERVICE)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom">
		<div class="enterprise">
			<p>Are you a large employer (500+ head count) with multiple sites? You'll need something more.<br/>Get in touch to discuss. <a href="tel:+447591208175">07591 208175</a></p>
		</div>
		<p class="access" (click)="startSubscription(Plan.MONTHLY)">Monthly access £399 (without support)</p>
	</div>
	<mat-icon class="close" (click)="close()">close</mat-icon>
</ng-container>
