import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {OfflinePaymentsDialogButtonDirective} from '@caroo/payment/buttons/offline-payments-dialog-button.directive';
import {PaymentInfoButtonDirective} from '@caroo/payment/buttons/payment-info-button.directive';
import {SubscriptionButtonDirective} from '@caroo/payment/buttons/subscription-button.directive';
import {OfflinePaymentsDialogModule} from '@caroo/payment/offline-payments-dialog/offline-payments-dialog.module';

@NgModule({
	imports: [
		MatDialogModule,
		OfflinePaymentsDialogModule
	],
	declarations: [
		OfflinePaymentsDialogButtonDirective,
		PaymentInfoButtonDirective,
		SubscriptionButtonDirective
	],
	exports: [
		OfflinePaymentsDialogButtonDirective,
		PaymentInfoButtonDirective,
		SubscriptionButtonDirective
	]
})
export class PaymentButtonModule {
}
