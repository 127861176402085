/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-payments-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../shared/button/button.component.ngfactory";
import * as i5 from "../../shared/button/button.component";
import * as i6 from "../subscription-plan-options/subscription-plan-options.component.ngfactory";
import * as i7 from "../subscription-plan-options/subscription-plan-options.component";
import * as i8 from "@angular/common";
import * as i9 from "./offline-payments-dialog.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../payment.service";
var styles_OfflinePaymentsDialogComponent = [i0.styles];
var RenderType_OfflinePaymentsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflinePaymentsDialogComponent, data: {} });
export { RenderType_OfflinePaymentsDialogComponent as RenderType_OfflinePaymentsDialogComponent };
function View_OfflinePaymentsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "label", [["class", "email-sent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["done"])), (_l()(), i1.ɵted(-1, null, [" Email Sent! "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OfflinePaymentsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["caroo-primary-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setupOfflineSubscription(_co.selectedPlan) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Proceed"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedPlan; _ck(_v, 0, 0, currVal_0); }); }
export function View_OfflinePaymentsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Offline Payments"], ["src", "assets/images/offline-payments.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Set up your plan"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["An email will be sent to you with the Caroo bank details so you can subscribe and pay. It\u2019s only \u00A3399+vat per month or \u00A32000+vat per year for unlimited use."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If payment is not received within 7 days, your account will be suspended until payment is made."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "caroo-subscription-plan-options", [], null, [[null, "selectedPlanChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedPlanChange" === en)) {
        var pd_0 = ((_co.selectedPlan = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_SubscriptionPlanOptionsComponent_0, i6.RenderType_SubscriptionPlanOptionsComponent)), i1.ɵdid(9, 49152, null, 0, i7.SubscriptionPlanOptionsComponent, [], { selectedPlan: [0, "selectedPlan"] }, { selectedPlanChange: "selectedPlanChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfflinePaymentsDialogComponent_1)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["setupSubscription", 2]], null, 0, null, View_OfflinePaymentsDialogComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPlan; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.isEmailSent; var currVal_2 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_1, currVal_2); }, null); }
export function View_OfflinePaymentsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-offline-payments-dialog", [], null, null, null, View_OfflinePaymentsDialogComponent_0, RenderType_OfflinePaymentsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.OfflinePaymentsDialogComponent, [i10.MAT_DIALOG_DATA, i11.PaymentService], null, null)], null, null); }
var OfflinePaymentsDialogComponentNgFactory = i1.ɵccf("caroo-offline-payments-dialog", i9.OfflinePaymentsDialogComponent, View_OfflinePaymentsDialogComponent_Host_0, {}, {}, []);
export { OfflinePaymentsDialogComponentNgFactory as OfflinePaymentsDialogComponentNgFactory };
